//中文繁體zh-WT.js
// import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import zhLocale from 'element-plus/dist/locale/zh-tw.mjs'
export default {
  home: {
    line1: '存儲提供商招募活動有效期為2023年7月5日至2023年8月4日。',
    line2: '在此期間如50PB存儲容量提前完成招募則此次活動對應提前結束，我們每天會為您更新展示整體招募進度。',
    line3: '活動解釋權歸DMC基金會所有，如有任何疑問請發送郵件至',
    line4: '或加入Telegram',
    line5: '，我們將為您提供在線技術支持。',
    line6: '請您在申請頁面填寫如下資訊',
    line6_1: '，您的資質將会審核，結果會第一時間通過郵件反饋給您:',
    line7: '郵箱地址：同一個電子郵件只能提交一次。',
    line8: '存儲容量空間：低於1TB或者超過10TB將不予通過。',
    line9: '填寫用於部署礦池軟件的服務器的公網IP地址 (中國大陸地區IP禁止註冊)：確保真實有效且同 1個IP 只能提交一次。',
    line10: '所在国家或地区。',
    line11: '除此之外，請提前准備好服務器資源，推薦配置為：',
    system: '繫統: ',
    systemContent: 'ubuntu 20.04',
    CPU: 'CPU: ',
    CPUContent: ' 8C 16線程',
    memory: '內存: ',
    memoryContent: '128G RAM',
    disk: '硬盤: ',
    diskContent: '不低於2T SSD',
    bandwidth: '帶寬: ',
    bandwidthContent: '不低於10MB',
    attention: '註意:  ',
    attentionContent: '該配置是礦池軟件服務器配置，部署好之後请將您的存儲接入。',
    apply: '申請',
    checkProgress: '査詢進度',
    homePage: '首頁',
    email: '郵箱',
    ipAddress: '公網IP',
    area: '地區',
    space: '空間',
    bandwidth2: '帶寬',
    submit: '提交',
    emptyEmail: '請填寫郵箱',
    existEmail: '郵箱已存在',
    emptyBandwidth: '請填寫帶寬',
    emptyIP: '請填寫公網IP地址',
    emptyArea: '請填寫所在地區',
    emptySpace: '請選擇空間大小',
    emptyPublicKey: '填入通過公鑰創建工具生成的公鑰，私鑰請自行妥善保管',
    successSubmit: '提交成功',
    status: '狀態',
    approved: '已審批',
    unapproved: '審批中',
    reject: '已拒絕',
    applyTitle: '申請成為存儲提供商',
    notes: '備註',
    example: '示例:127.0.0.1',
    select: '選擇',
    hasSend: '已經將DMC帳號發到您的郵箱',
    resource: '資源下載',
    software1: '生態軟件1',
    software2: '生態軟件2',
    linux: '礦池軟件linux版',
    win: '礦池軟件win版',
    poolSoft: '礦池軟件',
    actionTxt: '礦工部署及操作手册',
    publicKey: '公鑰生成工具：',
    publicLabel: '公鑰',
    publicNo: '公鑰：請從資源下載頁面下載公鑰創建工具並生成您的公鑰。請記住，不要向任何人透露您的私鑰。',
    keyCreator: 'key 生成工具',
    errorKey: '請輸入key 生成工具生成的公鑰',
    foggieMac: 'MAC版客戶端',
    foggieLinux: 'Linux版客戶端',
    foggieWin: 'WIN版客戶端',
    foggieGuide: '客戶端操作手册',
    bakeGuide: '客戶端部署和操作手册',
    code: '驗證碼',
    emptyCode: '請輸入郵箱驗證碼'

  },
  ...zhLocale,

}
