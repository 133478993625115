// import enLocale from "element-ui/lib/locale/lang/en";
import enLocale from 'element-plus/dist/locale/en.mjs'
export default {
  home: {
    line1: 'The Storage Providers Recruitment Campaign is from July 5, 2023, to August 4, 2023. ',
    line2: 'Once 50PB storage capacity gets recruited during this period and this campaign will terminate immediately. The overall recruitment progress will be updated daily.',
    line3: 'The interpretation rights of the campaign belong to the DMC Foundation. If you have any questions, please send an email to',
    line4: 'or join the Telegram group: ',
    line5: ', we will provide online technical support for you.',
    line6: 'Please fill the below information on application page',
    line6_1: ', your qualifications will be reviewed, and the result will be replied via email as soon as possible:',
    line7: 'Email address: each email can only be submitted once.',
    line8: 'Storage capacity: storage capacity below 1TB or above 10TB will not be accepted.',
    line9: 'Public IP address of the server used for deploying the mining pool software (registration with IP addresses from mainland China is prohibited). Please ensure the IP address is genuine, valid, and only submit once per IP address.',
    line10: 'Location.',
    line11: 'Besides, please prepare server resources in advance. Recommended configuration includes:',
    system: 'Operating System: ',
    systemContent: 'ubuntu 20.04',
    CPU: 'CPU:',
    CPUContent: '8 cores, 16 threads',
    memory: 'Memory:',
    memoryContent: '128G RAM',
    disk: 'Hard Drive:',
    diskContent: 'SSD, minimum 2TB',
    bandwidth: 'Bandwidth: ',
    bandwidthContent: 'Minimum 10MB',
    attention: 'Note: ',
    attentionContent: 'This configuration is for the mining pool software server. Once deployed, please integrate your storage.',
    apply: 'Apply',
    checkProgress: 'Query progress',
    homePage: 'Home page',
    email: 'Email',
    ipAddress: 'IP Address',
    area: 'Location',
    space: 'Space',
    bandwidth2: 'Bandwidth',
    submit: 'Submit',
    emptyEmail: 'Please fill in the email address',
    existEmail: 'Email already exists',
    emptyBandwidth: 'Please fill in the bandwidth',
    emptyIP: 'Please fill in the public IP address',
    emptyArea: 'Please fill in the location',
    emptySpace: 'Please select the space size',
    emptyPublicKey: 'Fill in the public key generated through the public key generation tool, please keep the private key properly by yourself',
    successSubmit: 'Submitted successfully',
    status: 'Status',
    approved: 'Approved',
    unapproved: 'Under  review',
    reject: 'Rejected',
    applyTitle: 'Apply to become a storage provider',
    notes: 'Notes',
    example: 'Example:127.0.0.1',
    select: 'Select',
    hasSend: 'The DMC account has been sent to your email',
    resource: 'Resource Download',
    software1: 'Ecological Software 1',
    software2: 'Ecological Software 2',
    linux: 'Mining pool software for Linux',
    win: 'Mining pool software for Win',
    poolSoft: 'Mining pool software',
    actionTxt: 'Miner deployment and operation manual',
    publicKey: 'Public key generation tool:',
    publicLabel: 'Public key',
    publicNo: 'Public key: please download keycreator tool from Resource download page and generate your public key. Please remember DO NOT disclose your private key to anyone.',
    keyCreator: 'Key generation tool',
    errorKey: 'Please enter the public key generated by the public key generation tool',
    foggieMac: 'MAC version client',
    foggieLinux: 'Linux version client',
    foggieWin: 'WIN version client',
    foggieGuide: 'Client operation manual',
    bakeGuide: 'Client deployment and operation manual',
    code: 'Verification code',
    emptyCode: 'Please enter the email verification code'
  },
  ...enLocale,

}
