<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/Apply">Apply for</router-link>
  </nav> -->
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background: linear-gradient(171deg, #272eef, #207ee4 42%, #e392ff);
  min-height: 100vh;
}

* {
  padding: 0;
  margin: 0;
  overflow: visible;
}
</style>
