<template>
  <div class="main">
    <div class="top-block">
      <video src="@/assets/index/number.mp4" muted autoplay loop></video>
      <div class="top-content">
        <header class="header">
          <div class="logo-box">
            <img class="left-logo1" src="@/assets/system/LogoTitle.png" />
          </div>
        </header>
        <div class="header-bottom">
          <div class="top-left">
            <img src="@/assets/index/portal-index3.png" alt="" />
          </div>
          <div class="top-right">
            <p class="title">
              Get <span> Foggie </span> to start your Web3 Journey
            </p>
            <p class="desc">
              Foggie/FoggieX Desktop is your one-stop digital storage solution.
              Let's explore and enjoy the limitless possibilities of the digital
              world together.
            </p>
            <div class="download-btn-list">
              <div class="download-btn">
                <a
                  :href="`/beta/software/client_pro/windows/${winVersion2}.exe`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="btn-item">
                    <img src="@/assets/index/win.png" alt="" />
                    <span>FoggieX Desktop for Windows X86 </span>
                  </div>
                </a>

                <a
                  :href="`/beta/software/client_pro/mac/Mac-x64/${macVersion2}.dmg`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="btn-item">
                    <img src="@/assets/index/mac.png" alt="" />
                    <span>FoggieX Desktop for Apple Intel Chip </span>
                  </div>
                </a>

                <a
                  :href="`/beta/software/client_pro/mac/Mac-arm/${armVersion2}.dmg`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="btn-item">
                    <img src="@/assets/index/mac.png" alt="" />
                    <span>FoggieX Desktop for Apple M1/M2 Chip</span>
                  </div>
                </a>
              </div>
              <div class="download-btn download-btn-v2">
                <a
                  :href="`/beta/software/client/windows/${winVersion}.exe`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="btn-item">
                    <img src="@/assets/index/win.png" alt="" />
                    <span>Foggie Desktop for Windows X86 </span>
                  </div>
                </a>

                <a
                  :href="`/beta/software/client/mac/Mac-x64/${macVersion}.dmg`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="btn-item">
                    <img src="@/assets/index/mac.png" alt="" />
                    <span> Foggie Desktop for Apple Intel Chip </span>
                  </div>
                </a>

                <a
                  :href="`/beta/software/client/mac/Mac-arm/${armVersion}.dmg`"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="btn-item">
                    <img src="@/assets/index/mac.png" alt="" />
                    <span>Foggie Desktop for Apple M1/M2 Chip</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background: #334ab3; width: 100%; padding: 80px 0">
      <div class="step-box">
        <p class="title">Latest Foggie Desktop Features</p>
        <div class="step"></div>
        <div class="four-block">
          <div class="block-item">
            <img src="@/assets/index/ipfs.png" alt="" />
            <p>Accelerate your IPFS content</p>
          </div>
          <div class="block-item">
            <img src="@/assets/index/back.png" alt="" />
            <p>Back up your data</p>
          </div>
          <div class="block-item">
            <img src="@/assets/index/nft.png" alt="" />
            <p>Mint your NFTs</p>
          </div>
          <div class="block-item">
            <img src="@/assets/index/dmc.png" alt="" />
            <p>Earn DMC Rewards</p>
          </div>
        </div>
        <div class="smile"></div>
      </div>
    </div>

    <div style="background: #efefef; width: 100%">
      <p class="support-title">GET SUPPORT</p>
      <div class="support-box">
        <div>
          <img src="@/assets/index/manual.png" alt="" />
          <!-- <a
            href="/beta/documents/client/EN_Foggie_Client_Operation_Manual_v1.4.pdf"
            target="_blank"
            >View the Product Manual
          </a> -->
          <a
            href="https://fog-works.gitbook.io/foggie-max-io-user-manual"
            target="_blank"
            >View the Product Manual
          </a>
          <p>
            Get the technical breakdown on Foggie Desktop, its features, and
            release notes.
          </p>
        </div>
        <div>
          <img src="@/assets/index/discord2.png" alt="" />
          <a
            href="https://discord.com/channels/1046683342025789541/1070536042677030973/1070584672066752573"
            target="_blank"
            >Join Our Community
          </a>
          <p>
            Virtual assistance. <br />
            Talk to us about any concerns, 24/7.
          </p>
        </div>
      </div>
    </div>

    <div class="product-foot">
      <div class="product-foot-content2">
        <img
          src="@/assets/system/logo.png"
          style="width: 180px; margin-left: 40px; object-fit: contain"
          alt=""
        />
        <div class="foot-content-right">
          <div class="foot-content-right-top">
            <p>CONTACT</p>
            <p class="line"></p>
            <p style="font-size: 20px">Get in Touch</p>
          </div>
          <div class="foot-content-right-bottom">
            <a href="https://twitter.com/fogworksinc" target="_blank">
              <img src="@/assets/index/twitter.svg" alt="" />
            </a>
            <a
              href="https://discord.com/channels/1046683342025789541/1070536042677030973"
              target="_blank"
            >
              <img src="@/assets/index/discord.png" alt="" />
            </a>
            <a href="https://www.facebook.com/fogworksinc" target="_blank">
              <img src="@/assets/index/facebook.svg" alt="" />
            </a>
            <a href="https://www.youtube.com/@fogworksinc.2753" target="_blank">
              <img src="@/assets/index/youtube.png" alt="" />
            </a>
            <a href="https://www.instagram.com/fog_works/" target="_blank">
              <img src="@/assets/index/camera.png" alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/fog-works/"
              target="_blank"
            >
              <img src="@/assets/index/linkedin.png" alt="" />
            </a>
            <a href="https://medium.com/fog-works-inc" target="_blank">
              <img
                style="width: 40px"
                src="@/assets/index/3radius.png"
                alt=""
              />
            </a>
            <a href="mailto:support@fogworks.io" target="_blank">
              <img src="@/assets/index/email.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- 支付底部说明 -->
    <div class="product-foot" style="padding-top: 20px; padding-bottom: 70px">
      <div class="product-foot-content2 product-foot-content3">
        <!-- <a href="payment-policy" style="text-decoration: none"> -->
        <div class="product-foot-content-logo" @click="gotoPayment('Terms')">
          <div class="product-foot-content-icon">
            <p class="eTYfuI">Terms and Conditions</p>
          </div>
        </div>
        <div
          class="product-foot-content-logo"
          @click="gotoPayment('paymentPolicy')"
        >
          <div class="product-foot-content-icon">
            <p class="eTYfuI">Payment Policy</p>
          </div>
        </div>
        <!-- </a > -->
        <!-- <a href="#terms-and-conditions" style="text-decoration: none"> -->

        <!-- </a> -->
        <!-- <a
          aria-current="page"
          class=""
          href="#privacy-policy"
          style="text-decoration: none"
        > -->
        <div
          class="product-foot-content-logo"
          @click="gotoPayment('PrivacyPolicy')"
        >
          <div class="product-foot-content-icon">
            <p class="eTYfuI">Privacy Policy</p>
          </div>
        </div>
        <div class="product-foot-content-logo">
          <div class="product-foot-content-icon">
            <a href="https://fogworks.io/">
              <p class="eTYfuI">© 2023 Fog Works,Inc.</p>
            </a>
          </div>
        </div>
        <!-- </a> -->
        <!-- <div class="product-foot-content-logo">
          <div class="product-foot-content-icon">
            <img
              src="@/assets/system/visa.svg"
              alt="icon"
              class="product-foot-content-img"
            />
            <img
              src="@/assets/system/master.svg"
              alt="icon"
              class="product-foot-content-img"
            />
            <img
              src="@/assets/system/discover.svg"
              alt="icon"
              class="product-foot-content-img"
            />
            <img
              src="@/assets/system/amex.svg"
              alt="icon"
              class="product-foot-content-img"
            />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adoptImg: require("@/assets/index/portal-index1.png"),
      currentLanguage: "",
      countDownDay: 0,
      countDownHour: 0,
      countDownMinutes: 0,
      countDownSecond: 0,
      activeName: "",
      armVersion: "",
      macVersion: "",
      winVersion: "",

      armVersion2: "",
      macVersion2: "",
      winVersion2: "",
    };
  },
  mounted() {
    fetch("/beta/software/client/metadata.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.armVersion = data.armVersion;
        this.macVersion = data.macVersion;
        this.winVersion = data.winVersion;
        console.log(data, "data1111");
      });
    fetch("/beta/software/client_pro/metadata.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.armVersion2 = data.armVersion;
        this.macVersion2 = data.macVersion;
        this.winVersion2 = data.winVersion;
        console.log(data, "data222");
      });
    let key = 1;
    setInterval(() => {
      this.adoptImg = require(`@/assets/index/portal-index${key}.png`);
      if (key == 3) {
        key = 1;
      } else {
        key++;
      }
    }, 5000);
  },
  methods: {
    gotoPayment(path) {
      let _path = "";
      if (path === "paymentPolicy") {
        _path = "/paymentPolicy";
      } else if (path === "Terms") {
        _path = "/termsConditions";
      } else if (path === "PrivacyPolicy") {
        _path = "/privacyPolicy";
      }
      // let language_key = window.localStorage.getItem("language_key");
      // if (language_key == "en") {
      _path = _path + "_EN";
      // }
      this.$router.push(_path);
    },
  },
};
</script>
<!-- <script>
function showVersion(data) {
  console.log(data);
}
</script> -->
<!-- <script src="http://156.242.10.13/beta/software/client/metadata.js?callback=showVersion"></script> -->

<style lang="scss">
@import "@/static/style/index.scss";
.adopt-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  img {
    width: 470px;
    margin-left: 50px;
    object-fit: contain;
    transition: all 0.5s;
  }
}
.top-block {
  position: relative;
  overflow: hidden;
  height: 780px;
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0.3;
  }
  .header-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 1340px;
    margin: 0 auto;
    color: #fff;
    position: relative;
  }
  .top-right-v2 {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .top-left {
    position: relative;
    width: 610px;
    height: 600px;
    background-image: url("~@/assets/index/computer.png");
    background-repeat: no-repeat;
    background-size: contain;
    img {
      position: absolute;
      top: 129px;
      left: 57px;
      width: 485px;
    }
  }
  .top-right {
    .title {
      text-align: center;
      font-weight: 900;
      font-size: 60px;
      span {
        color: #ffd057;
      }
    }
    .desc {
      margin: 20px 40px 40px;
    }
  }
  .download-btn {
    a {
      display: block;
      width: 380px;
      margin: 10px auto;

      text-decoration: none;
    }
    .btn-item {
      // width: 340px;
      height: 60px;
      line-height: 60px;
      // margin: 10px auto;
      color: #0007eb;
      background: #fff;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s;
      font-size: 14px;

      img {
        float: left;
        width: 50px;
        padding: 5px;
        vertical-align: middle;
      }
      &:hover {
        transform: translateY(-5px);
      }
    }
  }
  .download-btn-list {
    display: flex;
    .download-btn-v2 {
      margin-left: 30px;
    }
  }
}
.top-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.step-box {
  width: 1250px;
  margin: 0 auto;
  .title {
    margin-bottom: 20px;
    text-align: center;
    font-weight: 900;
    font-size: 52px;
    color: #fff;
  }
  .step {
    width: 800px;
    height: 50px;
    margin: 20px auto;
    background: url("~@/assets/index/step.png") no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.four-block {
  display: grid;
  width: 1000px;
  margin: 60px auto;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  .block-item {
    text-align: center;
  }
  img {
    height: 150px;
  }
  p {
    margin-top: 10px;
    height: 72px;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: #fff;
  }
}
.smile {
  width: 730px;
  height: 210px;
  margin: 20px auto;
  background: url("~@/assets/index/smile.png") no-repeat;
  background-position: center;
  background-size: cover;
}
.support-title {
  padding: 80px 0 40px;
  text-align: center;
  font-size: 60px;
  font-weight: 900;
  color: #0a0a93;
}
.support-box {
  display: grid;
  width: 1000px;
  margin: 0 auto;
  padding-bottom: 90px;
  grid-template-columns: repeat(2, 1fr);
  > div {
    text-align: center;
  }
  img {
    display: block;
    height: 250px;
    margin: 0 auto 30px;
  }
  a {
    font-size: 23px;
    font-weight: 700;
  }
  p {
    width: 415px;
    margin: 20px auto;
    font-size: 18px;
  }
}
</style>
<style>
.edHUud {
  position: absolute;
  max-width: 260px;
  margin-top: 10px;
  padding: 20px;
  background: rgba(15, 14, 71, 0.3);
  box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
  backdrop-filter: blur(40px) brightness(80%) saturate(150%);
  border-radius: 20px;
  visibility: visible;
  opacity: 1;
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  transform-origin: center top;
  transform: rotateX(0deg) translateY(0px);
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .edHUud {
    transform: skewY(0deg) rotate(0deg) translateY(0px);
  }
}
.product-foot-content-logo {
  height: 44px;
  transition: all 0.3s ease-in-out 0s;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.product-foot-content-icon {
  display: inline-flex;
}
.product-foot-content-img {
  width: 30px;
  /* height: 30px; */
  margin: auto;
}
.eTYfuI {
  font-style: normal;
  font-size: 20px;
  font-weight: normal;
  line-height: 130%;
  color: rgb(255, 255, 255);
  text-align: center;
  margin: auto auto auto 10px;
  text-decoration: underline;
}
.fHhSbH {
  position: relative;
  overflow: hidden;
}
.iqeesP {
  position: absolute;
  width: 100%;
  height: 600px;
  background: linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: -1;
}
.chIxts {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(
    rgba(47, 184, 255, 0.42) 31.77%,
    rgb(158, 236, 217) 100%
  );
  opacity: 0.4;
  z-index: 0;
  left: 90%;
  top: 150px;
  width: 228px;
  height: 228px;
  mix-blend-mode: overlay;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.ftCKGc {
  position: absolute;
  border-radius: 50%;
  opacity: 0.4;
  z-index: 0;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  width: 84px;
  height: 84px;
  left: 10%;
  top: 286.34px;
  background: linear-gradient(rgb(47, 184, 255) 0%, rgb(158, 236, 217) 100%);
  mix-blend-mode: color-dodge;
}
.kcCXKN {
  position: absolute;
  background: linear-gradient(
    rgb(189, 225, 220) 0%,
    rgba(191, 227, 222, 0) 100%
  );
  border-radius: 40px;
  transform: rotate(-130deg);
  width: 52px;
  height: 320px;
  top: 50px;
  left: 30%;
  opacity: 0.3;
  z-index: -1;
}
.dyNjyN {
  position: absolute;
  background: linear-gradient(
    rgb(189, 225, 220) 0%,
    rgba(191, 227, 222, 0) 100%
  );
  border-radius: 40px;
  transform: rotate(-130deg);
  opacity: 0.3;
  z-index: -1;
  width: 37px;
  height: 304px;
  left: 60%;
  top: 150.75px;
}
.dUHIft {
  position: absolute;
  background: linear-gradient(
    rgb(189, 225, 220) 0%,
    rgba(191, 227, 222, 0) 100%
  );
  border-radius: 40px;
  transform: rotate(-130deg);
  opacity: 0.3;
  z-index: -1;
  width: 17.4px;
  height: 143px;
  left: 70%;
  top: 189.75px;
}
.bBnfmP {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave1);
  top: 400px;
  height: 300px;
  background: linear-gradient(
    111.34deg,
    rgb(132, 78, 255) 0.04%,
    rgb(136, 248, 255) 100.04%
  );
  opacity: 0.5;
  mix-blend-mode: color-dodge;
}
@media (min-width: 1440px) {
  .bBnfmP {
    transform: scale(1.5);
    left: -1px;
  }
}
@media (min-width: 2200px) {
  .bBnfmP {
    transform: scale(2);
  }
}
.hvMPmp {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave2);
  top: 450px;
  height: 400px;
  background: linear-gradient(
    218.31deg,
    rgb(0, 122, 255) 0%,
    rgb(80, 164, 255) 100%
  );
}
@media (min-width: 1440px) {
  .hvMPmp {
    transform: scale(1.5);
    left: -1px;
  }
}
@media (min-width: 2200px) {
  .hvMPmp {
    transform: scale(2);
  }
}
.dcLUAu {
  position: absolute;
  width: 100%;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave3);
  top: 450px;
  height: 600px;
  background: linear-gradient(
    rgba(242, 246, 255, 0.5) 0%,
    rgb(242, 246, 255) 40%
  );
  backdrop-filter: saturate(200%);
  z-index: -1;
}
@media (min-width: 1440px) {
  .dcLUAu {
    transform: scale(1.5);
    left: -1px;
  }
}
@media (min-width: 2200px) {
  .dcLUAu {
    transform: scale(2);
  }
}

.products-info {
  /* max-width: 460px; */
  text-align: center;
  margin: 0px auto;
  padding: 200px 20px 0px;
  display: grid;
  gap: 20px;
  z-index: 1;
}
.products-info-h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin: 0px;
  color: rgb(255, 255, 255);
}
.products-info-p {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 130%;
  margin: 0px;
  color: rgba(255, 255, 255, 0.7);
  /* padding: 0px 0px 80px; */
}
.eBKfWo {
  position: relative;
  width: 100%;
  top: 500px;
}
.hocmRq {
  position: absolute;
  height: 1500px;
  width: 100%;
  overflow: hidden;
  display: none;
  opacity: 0;
  animation: 1s ease 0s 1 normal forwards running jBcSpD;
}
.jTMytU {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave1);
  top: 0px;
  height: 250px;
  background: linear-gradient(
    200.44deg,
    rgb(67, 22, 219) 13.57%,
    rgb(144, 118, 231) 58.38%
  );
  opacity: 0.2;
}
@media (min-width: 1440px) {
  .jTMytU {
    transform: scale(1.5);
    left: -1px;
  }
}
@media (min-width: 2200px) {
  .jTMytU {
    transform: scale(2);
  }
}
.jTXiRU {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave2);
  top: 20px;
  height: 250px;
  background: linear-gradient(
    132.86deg,
    rgb(255, 36, 36) -4.28%,
    rgb(193, 52, 122) 14.43%,
    rgb(73, 30, 184) 34.1%
  );
}
@media (min-width: 1440px) {
  .jTXiRU {
    transform: scale(1.5);
    left: -1px;
  }
}
@media (min-width: 2200px) {
  .jTXiRU {
    transform: scale(2);
  }
}
.hDCskI {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave3);
  top: 40px;
  height: 500px;
  background: linear-gradient(
    rgba(242, 246, 255, 0.5) 0%,
    rgb(242, 246, 255) 40%
  );
  backdrop-filter: saturate(200%);
}
@media (min-width: 1440px) {
  .hDCskI {
    transform: scale(1.5);
    left: -1px;
  }
}
@media (min-width: 2200px) {
  .hDCskI {
    transform: scale(2);
  }
}
.bUEcUU {
  position: absolute;
  background-position: center top;
  background-repeat: no-repeat;
  top: 380px;
  height: 600px;
  background-size: 1440px;
  width: 100%;
  z-index: 0;
}
.product-info-bottom {
  position: relative;
  display: grid;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 30px;
  margin: 200px auto;
  padding: 0px 30px;
}
.product-foot {
  font-family: Yahei;
  background: linear-gradient(to right, #7964e3 0%, #334ab3 50%, #013690 100%);
  padding-top: 150px;
}

@media (min-width: 2000px) {
  .product-foot-bg1 {
    top: 450px;
  }
}
.product-foot-bg2 {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave1);
  top: 0px;
  height: 250px;
  background: linear-gradient(
    rgba(184, 202, 239, 0.24) -5.59%,
    rgb(229, 238, 244) 23.5%
  );
  opacity: 0;
  animation: 1s ease 0s 1 normal forwards running jBcSpD;
  display: none;
}
@media (min-width: 1440px) {
  .product-foot-bg2 {
    transform: scale(1.5);
    left: -1px;
  }
}
@media (min-width: 2200px) {
  .product-foot-bg2 {
    transform: scale(2);
  }
}
.product-foot-bg3 {
  position: absolute;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transform-origin: left top;
  clip-path: url(#wave2);
  top: 30px;
  height: 600px;
  background: linear-gradient(rgba(19, 12, 62, 0.8) 0%, rgb(30, 19, 87) 28%);
}
@media (min-width: 1440px) {
  .product-foot-bg3 {
    transform: scale(1.5);
    left: -1px;
  }
}
@media (min-width: 2200px) {
  .product-foot-bg3 {
    transform: scale(2);
  }
}
.product-foot-content2 {
  width: 1160px;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.foot-content-right {
  width: 430px;
  margin-left: 100px;
}
.line {
  width: 250px;
  height: 2px;
  margin: 20px 0;
  box-shadow: 0 0 3px 2px #29abff;
  background: #fff;
}
.foot-content-right-top p {
  color: #fff;
  font-weight: 600;
  font-size: 35px;
}

.foot-content-right-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}
.foot-content-right-bottom img {
  width: 40px;
  object-fit: contain;
}
.product-foot-content3 {
  width: 1160px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 20px;
}
.product-foot-content-logo {
  height: 44px;
  transition: all 0.3s ease-in-out 0s;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
.foot-word {
  margin-left: 50px;
  width: 280px;
  color: rgba(255, 255, 255, 0.7);
  /* padding: 64px 0px; */
  font-size: 13px;
}
.ceQOIN {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  color: rgba(255, 255, 255, 0.6);
  margin: 20px 0px;
}
.foot-word a {
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0px;
  text-decoration: none;
}
.login-head-right {
  display: grid;
  /* grid-template-columns: repeat(7, auto); */
  grid-template-columns: 80px auto;
  /* gap: 26px; */
  gap: 10px;
  align-items: center;
}
.login-head-right .el-dropdown {
  margin: 14px 0;
  color: #fff;
}
.fNaEyC {
  height: 30px;
  display: flex;
  background: none;
  border: none;
  border-radius: 99px;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  background-blend-mode: overlay;
  background: #fff;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
}
.hEJekL {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(255, 255, 255);
  text-align: center;
  margin: auto auto auto 10px;
}
.fNaEyC p {
  transition: all 0.3s ease-in-out 0s;
}
.eoEZoK {
  margin: auto;
  height: 24px;
  width: 24px;
  object-fit: cover;
  border-radius: 0px;
  max-width: 100%;
  padding: 0;
}
/* .hEJekL {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(255, 255, 255);
  text-align: center;
  margin: auto auto auto 10px;
} */
.fNaEyC:hover {
  background: rgba(255, 255, 255, 0.2);
  background: #29abff;
  color: #fff;
  /* box-shadow: rgb(31 47 71 / 25%) 0px 20px 40px, rgb(0 0 0 / 10%) 0px 1px 5px,
    rgb(255 255 255 / 40%) 0px 0px 0px 0.5px inset; */
}

@media (max-width: 1200px) {
  .product-info-bottom {
    margin: 100px auto !important;
  }
}
.left-logo3 {
  margin-left: 10px;
  height: 18px;
}
</style>
