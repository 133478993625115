import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/dark/css-vars.css'
import "element-plus/dist/index.css";
import i18n from "@/static/language/index.js";


createApp(App).use(router).use(ElementPlus).use(i18n).mount('#app')
